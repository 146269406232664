import { serverAxios as axios } from '../utils/axios';

class AuthApi {
  async login({ email, password }) {
    // POST REQUEST TO LOGIN
    try {
      const res = await axios.post('/auth/', { email, password });
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async authenticate({ id, code2fa }) {
    try {
      // POST REQUEST TO LOGIN
      const res = await axios.post('/auth/2fa', { id, code2fa });
      // Failed
      if (res.status !== 200) {
        throw new Error(res.data.error);
      } else {
        return res.data;
      }
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async register({ email, name, password }) {
    console.log(email, name, password);
  }

  me(accessToken) {
    console.log(accessToken);
  }

  async logout(accessToken) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };

    // POST REQUEST TO LOGIN
    try {
      const res = await axios.delete('/auth/', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async refreshToken(retry = false) {
    // POST REQUEST TO LOGIN
    try {
      const res = await axios.post('/auth/refresh_token', {}, { retry });
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }
}

export const authApi = new AuthApi();
