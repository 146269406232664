import PropTypes from 'prop-types';
import { Badge, Box } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import { TagTypeColors, TagTypes } from '../../constants/environment-constants';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    left: -5,
    top: -8,
    border: `1px solid ${theme.palette.background.paper}`,
    borderRadius: '0',
    padding: '0 5px',
    fontSize: '9px',
    lineHeight: '9px',
    marginLeft: '20px',
    transform: 'translateX(0, -50%)',
    width: '40px'
  },
}));

export const EnvironmentTagBadge = (props) => {
  const { children, tag } = props;

  const tagText = TagTypes[tag];
  const tagColor = TagTypeColors[tag];

  return (
    <StyledBadge
      badgeContent={tagText}
      color={tagColor}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ pl: '42px' }}>{children}</Box>
    </StyledBadge>
  );
};

EnvironmentTagBadge.propTypes = {
  children: PropTypes.node,
  tag: PropTypes.number
};
