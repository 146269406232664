import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Box, Button, FormHelperText, Grid, Typography } from '@material-ui/core';
import { InputField } from '../input-field';
import { useAuth } from '../../hooks/use-auth';
import { useMounted } from '../../hooks/use-mounted';

export const Login2fa = () => {
  const mounted = useMounted();
  const { authenticate } = useAuth();
  const formik = useFormik({
    initialValues: {
      code2fa: '',
    },
    validationSchema: Yup.object().shape({
      code2fa: Yup.string().max(6).required('Two-factor code is required'),
    }),
    onSubmit: async (values, helpers) => {
      try {
        await authenticate(values.code2fa);

        if (mounted.current) {
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
        }
      } catch (err) {
        if (mounted.current) {
          helpers.setStatus({ success: false });
          helpers.setErrors({ code2fa: err });
          helpers.setSubmitting(false);

          toast.error(err);
        }
      }
    }
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          mb: 3
        }}
      >
        <Typography
          color="textPrimary"
          variant="h4"
        >
          Login
        </Typography>
        <Box sx={{ flexGrow: 1 }} />
      </Box>
      <Grid
        container
        spacing={2}
      >
        <Grid
          item
          xs={12}
        >
          <InputField
            autoFocus
            error={Boolean(formik.touched.code2fa && formik.errors.code2fa)}
            fullWidth
            helperText={formik.touched.code2fa && formik.errors.code2fa}
            label="Two-Factor Authentication Code"
            name="code2fa"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            type="text"
            value={formik.values.code2fa}
          />
        </Grid>
        {formik.errors.submit && (
          <Grid
            item
            xs={12}
          >
            <FormHelperText error>
              {formik.errors.submit}
            </FormHelperText>
          </Grid>
        )}
        <Grid
          item
          xs={12}
        >
          <Button
            color="primary"
            disabled={formik.isSubmitting}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Authenticate
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
