import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Typography
} from '@material-ui/core';
import { InputField } from './input-field';

export const NotesDialog = (props) => {
  const { open, onClose, onConfirm, required, ...other } = props;
  const formik = useFormik({
    initialValues: {
      notes: ''
    },
    validationSchema: Yup.object().shape({
      notes: required ? Yup.string().max(1500).required('Notes is required') : Yup.string().max(1500),
    }),
    onSubmit: async (values, helpers) => {
      try {
        // Send Notes On COnfirm
        await onConfirm(values.notes);

        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
        helpers.resetForm();
      } catch (err) {
        console.error(err);
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);

        toast.error(err.message);
      }
    }
  });

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionProps={{
        onExited: () => formik.resetForm()
      }}
      {...other}
    >
      <DialogTitle>
        Additional Notes
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            xs={12}
          >
            <Typography>
              Attach additional notes to your request.
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
          >
            <InputField
              error={Boolean(formik.touched.notes && formik.errors.notes)}
              helperText={formik.touched.notes && formik.errors.notes}
              label={`Notes ${required ? '(Required)' : ''}`}
              name="notes"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.notes}
              fullWidth
              multiline
              rows={3}
            />
          </Grid>
          {formik.errors.submit && (
            <Grid
              item
              xs={12}
            >
              <FormHelperText error>
                {formik.errors.submit}
              </FormHelperText>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={onClose}
          variant="text"
        >
          Close
        </Button>
        <Button
          color="primary"
          disabled={formik.isSubmitting}
          onClick={() => { formik.handleSubmit(); }}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NotesDialog.defaultProps = {
  open: false,
  required: false
};

NotesDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onConfirm: PropTypes.func,
  required: PropTypes.bool
};
