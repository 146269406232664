import { useEffect } from 'react';
import { matchPath, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEnvironment } from '../../hooks/use-environment';

export const EnvironmentGuard = ({ children }) => {
  const { environments } = useEnvironment();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!environments || environments.length <= 0) return;

    // EnvironmentId
    let environmentId = null;
    const match = matchPath({ path: '/dashboard/:environmentId/*', strict: false }, location.pathname);
    if (match) ({ environmentId } = match.params);

    // Environment
    const env = environments.find((e) => e.id === +environmentId);

    // If There are no environments
    if (!env) navigate('/environment/selector');
  }, [environments, location]);

  return <>{ children }</>;
};

EnvironmentGuard.propTypes = {
  children: PropTypes.node
};
