import { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { NotesDialog } from '../components/notes-dialog';

export const NotesContext = createContext({
  notes: '',
  saveNotes: () => {},
  addNotes: () => {}
});

export const NotesProvider = (props) => {
  const { children } = props;
  const [notes, setNotes] = useState('');
  const [open, setOpen] = useState(false);
  const [callback, setCallback] = useState(() => {});
  const [required, setRequired] = useState(false);

  const saveNotes = async (updatedNotes) => {
    setNotes(updatedNotes);
    // Close DIalog
    setOpen(false);
    // Run Callback Function
    await callback(updatedNotes);
  };

  const cancelNotes = async () => {
    // Close DIalog
    setOpen(false);
    // Run Callback Function
    // if (!required) await callback(null);
  };

  const addNotes = (cb, isRequired = false) => {
    // Reset Notes
    setNotes(null);
    // Set Required
    setRequired(isRequired);
    // Open Notes
    setOpen(true);
    // Set Callback
    setCallback(() => cb);
  };

  return (
    <NotesContext.Provider
      value={{
        notes,
        callback,
        saveNotes,
        addNotes
      }}
    >
      {children}
      <NotesDialog
        onConfirm={saveNotes}
        onClose={cancelNotes}
        open={open}
        required={required}
      />
    </NotesContext.Provider>
  );
};

NotesProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const NotesConsumer = NotesContext.Consumer;

export const useNotes = () => useContext(NotesContext);
