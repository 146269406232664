import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  ButtonBase,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@material-ui/core';
import { usePopover } from '../../hooks/use-popover';
import { Selector as SelectorIcon } from '../../icons/selector';
import { EnvironmentTagBadge } from './environment-tag-badge';
import { lightNeutral } from '../../colors';

export const EnvironmentPopover = (props) => {
  const navigate = useNavigate();
  const { currentEnvironment, environments, onEnvironmentChange, sx, darkMode, ...other } = props;
  const [anchorRef, open, handleOpen, handleClose] = usePopover();

  const handleEnvironmentChange = (environmentId) => {
    handleClose();
    onEnvironmentChange?.(environmentId);
  };

  const goToSelector = () => {
    // Redirect To Selector
    navigate('/environment/selector');
  };

  return (
    <>
      <ButtonBase
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          borderRadius: 1,
          display: 'flex',
          p: 1,
          width: 240,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)'
          },
          ...sx
        }}
        {...other}
      >
        <Typography
          color="textSecondary"
          sx={{
            color: darkMode ? 'primary.contrastText' : lightNeutral[900],
            mr: 2,
            display: {
              sm: 'block',
              xs: 'none'
            }
          }}
          variant="subtitle2"
        >
          {
            currentEnvironment
              ? (
                <EnvironmentTagBadge tag={+currentEnvironment.tag}>
                  {`${currentEnvironment.name}`}
                </EnvironmentTagBadge>
              ) : (
                'Select an Environment'
              )
          }
        </Typography>
        <Typography
          color="textSecondary"
          sx={{
            color: darkMode ? 'primary.contrastText' : lightNeutral[900],
            mr: 0,
            display: {
              sm: 'none',
              xs: 'block'
            },
            fontSize: '10px'
          }}
          variant="subtitle2"
        >
          {
            currentEnvironment
              ? currentEnvironment.name : 'Environment'
          }
        </Typography>
        <Box
          sx={{
            flexGrow: 1,
            display: {
              sm: 'block',
              xs: 'none'
            }
          }}
        />
        <SelectorIcon
          fontSize="small"
          sx={{
            display: {
              sm: 'block',
              xs: 'none'
            }
          }}
        />
      </ButtonBase>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <List>
          {environments.map((environment) => (
            <ListItem
              key={environment.id}
              button
              selected={environment.id === currentEnvironment?.id}
              onClick={() => handleEnvironmentChange(environment.id)}
            >
              <EnvironmentTagBadge tag={+environment.tag}>
                <ListItemText primary={`${environment.name}`} />
              </EnvironmentTagBadge>
            </ListItem>
          ))}
          <ListItem
            button
            onClick={() => goToSelector()}
          >
            <ListItemText primary="Go to the environment selector" />
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

EnvironmentPopover.propTypes = {
  currentEnvironment: PropTypes.object,
  onEnvironmentChange: PropTypes.func,
  environments: PropTypes.array.isRequired,
  sx: PropTypes.object,
  darkMode: PropTypes.bool
};
