import { useEffect, useRef } from 'react';

/**
 *
 * @param {Function} callback
 * @param {Number} delay
 * @param {Boolean} strict // Strict means it runs on the minute
 */

export const useInterval = (callback, delay, strict = false) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () => savedCallback.current();
    // Redo
    if (delay !== null) {
      let id;
      if (strict) {
        const date = new Date();
        setTimeout(() => {
          // Tick one time first
          tick();
          // Interval
          id = setInterval(tick, delay);
        }, (60 - date.getSeconds()) * 1000);
      } else {
        id = setInterval(tick, delay);
      }
      // Clear Interval
      return () => clearInterval(id);
    }
    return () => {};
  }, [delay]);
};
