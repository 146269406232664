import { serverAxios as axios } from '../utils/axios';

class UserApi {
  async me() {
    // GET REQUEST
    try {
      const res = await axios.get('/auth/');
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async getUsers({ page, query, sort, sortBy, status }, accessToken, environmentId) {
    let statusInt = 0;
    if (status) statusInt = 1;

    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { page, query, sort, sortBy, status: statusInt, environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get('/user/', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getUser({ userId }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/user/${userId}`, config);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async create(data, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      params: { environmentId }
    };

    // Form Data
    const formData = new FormData();
    // Append Destination
    formData.append('dest', 'user');
    // Loop Through Data
    Object.keys(data).forEach((key) => {
      const value = data[key];
      // Append
      formData.append(key, value);
    });

    // GET REQUEST
    try {
      const res = await axios.put('user', formData, config);
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async disable(userid, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.post(`/user/${userid}/disable`, {}, config);
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async enable(userid, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.post(`/user/${userid}/enable`, {}, config);
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async update(data, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      params: { environmentId }
    };

    const { userId } = data;
    // Form Data
    const formData = new FormData();
    // Append Destination
    formData.append('dest', 'user');
    // Loop Through Data
    Object.keys(data).forEach((key) => {
      const value = data[key];
      // Append
      if (key !== 'userId') formData.append(key, value);
    });

    // GET REQUEST
    try {
      const res = await axios.post(`/user/${userId}`, formData, config);
      return res.data;
    } catch (err) {
      throw err.response.data.error;
    }
  }

  async generate2fa(accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.post('/user/2fa', {}, config);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async activate2fa(code, secret2FA, url2FA, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    console.log(secret2FA, url2FA);

    // Request to enable 2FA
    try {
      const res = await axios.put('/user/2fa', { code, secret2FA, url2FA }, config);
      return res.data;
    } catch (err) {
      console.log(err);
      throw err.response.data.error;
    }
  }

  async disable2fa(code, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.post('/user/2fa/disable', { code }, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getRoles({ userId, page, query, sort, sortBy }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { page, query, sort, sortBy, environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/user/${userId}/role`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async setRole({ userId, environmentId: eid, roleId }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.put(`/user/${userId}/role`, { environmentId: eid, roleId }, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async removeRole({ userId, userRoleId }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.delete(`/user/${userId}/role/${userRoleId}`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async resetPassword(userId, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.post(`/user/${userId}/password`, {}, config);
      return res.data;
    } catch (err) {
      console.log(err);
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async changePassword({ userId, oldPassword, password }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.put(`/user/${userId}/password`, { oldPassword, password }, config);
      return res.data;
    } catch (err) {
      console.log(err);
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async resetPasswordByEmail(email, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.post('/auth/password', { email }, config);
      return res.data;
    } catch (err) {
      console.log(err);
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async setSuperAdmin({ userId, isSuperAdmin }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // Request to enable 2FA
    try {
      const res = await axios.post(`/user/${userId}/superadmin`, { isSuperAdmin }, config);
      return res.data;
    } catch (err) {
      console.log(err);
      console.log(err.response);
      throw err.response.data.error;
    }
  }
}

export const userApi = new UserApi();
