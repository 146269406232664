import PropTypes from 'prop-types';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

const breatheAnimation = keyframes`
  0% { opacity: 0.7; color: #868998; }
  50% {  opacity: 1.0; color: #9599ff; }
  100% {  opacity: 0.7; color: #868998; }
`;

export const TextAnimation = ({ children }) => (
  <StyledTypography>
    {children}
  </StyledTypography>
);

TextAnimation.propTypes = {
  children: PropTypes.string
};

const StyledTypography = styled.div`
  margin: 0;
  font-size: 14px;
  animation-name: ${breatheAnimation};
  animation-duration: 3s;
  animation-iteration-count: infinite;
`;
