import { serverAxios as axios } from '../utils/axios';

class EnvironmentApi {
  async get(id, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/environment/${id}`, {}, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getAll(accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get('/environment/', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async create(data, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      params: { environmentId }
    };

    // Form Data
    const formData = new FormData();
    // Append Destination
    formData.append('dest', 'environment');
    // Loop Through Data
    Object.keys(data).forEach((key) => {
      const value = data[key];
      // Append
      formData.append(key, value);
    });

    // GET REQUEST
    try {
      const res = await axios.put('/environment/', formData, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async update(data, accessToken, environmentId) {
    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'multipart/form-data'
      },
      params: { environmentId }
    };

    const { id } = data;
    // Form Data
    const formData = new FormData();
    // Append Destination
    formData.append('dest', 'environment');
    // Loop Through Data
    Object.keys(data).forEach((key) => {
      const value = data[key];
      // Append
      if (key !== 'id') formData.append(key, value);
    });

    // GET REQUEST
    try {
      const res = await axios.post(`/environment/${id}`, formData, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getSecret(id, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/environment/${id}/secret`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async regenerateSecret(id, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.post(`/environment/${id}/secret`, {}, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getModuleCounts(id, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get(`/environment/${id}/count`, config);
      return res.data;
    } catch (err) {
      throw err.response;
    }
  }
}

export const environmentApi = new EnvironmentApi();
