import { serverAxios as axios } from '../utils/axios';

class ActionRequestApi {
  async getActionRequests({ objectType, objectId, status, page }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { objectType, objectId, status, page, environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get('/actionrequest/', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async approveActionRequest(actionRequestId, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.post(`/actionrequest/${actionRequestId}`, {}, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async rejectActionRequest(actionRequestId, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.delete(`/actionrequest/${actionRequestId}`, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async rollbackActionRequest(actionRequestId, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.post(`/actionrequest/${actionRequestId}/rollback`, {}, config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }

  async getCount({ objectType, objectId }, accessToken, environmentId) {
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
      params: { objectType, objectId, environmentId }
    };

    // GET REQUEST
    try {
      const res = await axios.get('/actionrequest/count', config);
      return res.data;
    } catch (err) {
      console.log(err.response);
      throw err.response.data.error;
    }
  }
}

export const actionRequestApi = new ActionRequestApi();
